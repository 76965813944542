<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        <router-link to="/desired-delivery" class="back-button"
          ><i class="el-icon-back"></i
        ></router-link>
        <span>
          Хүслийн хүргэлт нэмэх
        </span>
      </h3>
      <div class="editField">
        <el-row :gutter="16">
          <el-col :span="16" :offset="4">
            <div class="panel">
              <el-form
                :model="bags"
                :rules="rules"
                ref="property"
                label-width="220px"
                class="demo-ruleForm"
              >
                <el-form-item label="Монгол нэр" prop="name_mon">
                  <el-input
                    style="width:250px"
                    size="mini"
                    v-model="bags.name_mon"
                    placeholder="Монгол нэр оруулна уу"
                  ></el-input>
                </el-form-item>
                <el-form-item label="Англи нэр" prop="name_eng">
                  <el-input
                    style="width:250px"
                    size="mini"
                    v-model="bags.name_eng"
                    placeholder="Англи нэр оруулна уу"
                  ></el-input>
                </el-form-item>
                <el-form-item label="Үнэ" prop="price">
                  <el-input
                    style="width:250px"
                    size="mini"
                    v-model="bags.price"
                    placeholder="Үнэ оруулна уу"
                  ></el-input>
                </el-form-item>
                <div class="text-center">
                  <el-button
                    class="mr10"
                    type="primary"
                    style="margin-right: 20;"
                    @click="save"
                    >Нэмэх</el-button
                  >
                  <router-link to="/desired-delivery" class="back-button">
                    <el-button>
                      Буцах
                    </el-button>
                  </router-link>
                </div>
              </el-form>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import service from "@/helpers/services.js";
export default {
  name: "desiredDeliveryAdd",
  created() {
    if (this.$route.name === "DesiredDeliveryAdd") {
      this.$notify({
        title: "Хүслийн жагсаалт нэмэх хуудас",
        message: "Мэдээллээ бүрэн гүйцэт оруулна уу",
        type: "info"
      });
    }
  },
  data() {
    return {
      bags: {
        name_mon: "",
        name_eng: "",
        price: "",
        created_at: ""
      },
      rules: {
        name_mon: [
          {
            required: true,
            message: "Та монгол нэрээ оруулна уу",
            trigger: "blur"
          }
        ],
        name_eng: [
          {
            required: true,
            message: "Та англи нэрээ оруулна уу",
            trigger: "blur"
          }
        ],
        price: [
          {
            required: true,
            message: "Та үнэ оруулна уу",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    save() {
      const body = {
        name_mon: this.bags.name_mon,
        name_eng: this.bags.name_eng,
        price: parseFloat(this.bags.price)
      };
      this.$refs.property.validate(valid => {
        if (valid) {
          if (this.$route.name === "DesiredDeliveryAdd") {
            service.createBag(body).then(response => {
              if (response.data.status === "success") {
                this.$notify({
                  title: "Амжилттай",
                  message: "Хүслийн жагсаалт нэмэгдлээ",
                  type: "success"
                });
                this.$router.go(-1);
              } else {
                this.$notify({
                  title: "Амжилтгүй",
                  message: response.message,
                  type: "warning"
                });
              }
            });
          }
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          return false;
        }
      });
    }
  }
};
</script>
<style>
.editField {
  margin-top: 25px;
}
</style>
